import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageResult, PaginationParameters } from '../../types';
import { Article, ArticlePost, ArticlePublish, ArticleUpdate } from './types';
import { DateTime } from 'luxon';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../error';
import { MessageService } from '../../service/message/message.service';
import { CrudType } from '../../service/message/types';

@Injectable({
  providedIn: 'root',
})
export class ArticleApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getPaged = (
    paginationParam: PaginationParameters,
  ): Observable<PageResult<Article>> => {
    let parameters: string[];
    parameters = [environment.features.content.articles];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('PageIndex', paginationParam.pageIndex);
    queryParams = queryParams.append('PageSize', paginationParam.pageSize);
    queryParams = queryParams.append(
      'Filter',
      paginationParam.filter.join(','),
    );

    return this.httpClient
      .get<PageResult<Article>>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getById = (id: string): Observable<Article> => {
    let parameters: string[];
    parameters = ['articles', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Article>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  postArticle = (articlePost: ArticlePost): any => {
    let parameters: string[];
    parameters = [environment.features.content.articles];
    let url = this.formatUrl(parameters);

    let formData = new FormData();
    formData.append('name', articlePost.name);
    formData.append('url', articlePost.url);
    formData.append('description', articlePost.description);
    formData.append('pagecontent', articlePost.pageContent);
    formData.append('thumbnail', articlePost.thumbnail);
    formData.append('background', articlePost.background);
    return this.httpClient
      .post<Article>(url, formData, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  publishArticle = (id: string): any => {
    let parameters: string[];
    parameters = ['publish', id];
    let url = this.formatUrl(parameters);
    let articlePut: ArticlePublish = {
      publicationDate: DateTime.now(),
    };
    return this.httpClient.put<Article>(url, articlePut).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  unpublishArticle = (id: string): any => {
    let parameters: string[];
    parameters = ['unpublish', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.put<Article>(url, '').pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  updateArticle = (articleUpdate: ArticleUpdate): any => {
    let parameters: string[];
    parameters = [environment.features.content.articles, articleUpdate.id];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<Article>(url, articleUpdate, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (id: string): Observable<boolean> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.content.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
