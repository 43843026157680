import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { IArticleState } from './article.reducer';
import { Article, articleFeatureKey } from './types';

const articleFeatureState =
  createFeatureSelector<IArticleState>(articleFeatureKey);

export const articleSelectors = {
  getPaged: createApiCallSelectors<PageResult<Article>, IArticleState>(
    articleFeatureState,
    'getPaged',
  ),
  getById: createApiCallSelectors<Article, IArticleState>(
    articleFeatureState,
    'getById',
  ),
  postArticle: createApiCallSelectors<any, IArticleState>(
    articleFeatureState,
    'postArticle',
  ),
  publishArticle: createApiCallSelectors<any, IArticleState>(
    articleFeatureState,
    'publishArticle',
  ),
  unpublishArticle: createApiCallSelectors<any, IArticleState>(
    articleFeatureState,
    'unpublishArticle',
  ),
  updateArticle: createApiCallSelectors<any, IArticleState>(
    articleFeatureState,
    'updateArticle',
  ),
  delete: createApiCallSelectors<boolean, IArticleState>(
    articleFeatureState,
    'delete',
  ),
};
