import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesTeamDetailsExternalAccountComponent } from '../sales-team-details-tab/sales-team-details-external-account/sales-team-details-external-account.component';
import { TabsContentSalesTeam } from '../../../../../shared/models/tabs-content-sales-team';

@Component({
  selector: 'app-sales-team-tabs-details',
  templateUrl: './sales-team-tabs-details.component.html',
  styleUrls: ['./sales-team-tabs-details.component.scss'],
})
export class SalesTeamTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentSalesTeam[]> = new BehaviorSubject<
    TabsContentSalesTeam[]
  >([]);

  tabsInit: TabsContentSalesTeam[] = [
    {
      title: 'SALES_TEAM_TABS_EXTERNAL_ACCOUNT',
      index: 1,
      name: 'external',
      component: SalesTeamDetailsExternalAccountComponent,
    },
  ];

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentSalesTeam) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
