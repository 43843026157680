import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { ArticleAction, articleActions } from './article.actions';
import { ArticleApiService } from './article.api.services';

@Injectable()
export class ArticleEffects {
  constructor(
    private readonly articleApiService: ArticleApiService,
    private readonly actions$: Actions<ArticleAction>,
  ) {}
  getPaged = createApiCallEffects(
    this,
    this.actions$,
    articleActions.getPaged,
    this.articleApiService.getPaged,
  );
  getById = createApiCallEffects(
    this,
    this.actions$,
    articleActions.getById,
    this.articleApiService.getById,
  );
  postArticle = createApiCallEffects(
    this,
    this.actions$,
    articleActions.postArticle,
    this.articleApiService.postArticle,
  );
  publishArticle = createApiCallEffects(
    this,
    this.actions$,
    articleActions.publishArticle,
    this.articleApiService.publishArticle,
  );
  unpublishArticle = createApiCallEffects(
    this,
    this.actions$,
    articleActions.unpublishArticle,
    this.articleApiService.unpublishArticle,
  );
  updateArticle = createApiCallEffects(
    this,
    this.actions$,
    articleActions.updateArticle,
    this.articleApiService.updateArticle,
  );
  delete = createApiCallEffects(
    this,
    this.actions$,
    articleActions.delete,
    this.articleApiService.delete,
  );
}
